<template>
  <div class="consultation">
    <div class="content1">
      <div class="container">
        <div
          class="row align-items-center justify-content-center"
          id="headline"
        >
          <div class="col-7 text-center">
            <div class="img" id="headline-img">
              <img
                class="img-fluid"
                src="../../assets/img/bg-contact.png"
                alt="Foto Headline"
                width="350px"
              />
            </div>
          </div>
          <div class="col-5">
            <div id="headline-text">
              <h4 style="font-weight: 700">Hubungi Kami</h4>
              <p style="font-size: 30px">
                ICH Institute senang mendengar kabar anda
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="conten2 mb-5 bg-custom p-5" style="height: 600px">
      <div class="container shadow p-3 mb-5 bg-body-tertiary rounded">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3982.0224936601203!2d98.73313737497311!3d3.5823077963918624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30313142461e0911%3A0x4cdb79ab209481a!2sYayasan%20iqra%20creative%20housu!5e0!3m2!1sen!2sid!4v1717856546916!5m2!1sen!2sid"
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        
      </div>
    </div>

    <div class="conten3 mt-5 mb-5">
      <div class="container">
        <div
          class="row align-items-center justify-content-center shadow p-3 mb-5 bg-body-tertiary rounded"
          id="headline"
        >
          <div class="col-5">
            <div id="headline-text">
              <h4>Bagiamana kami dapat membantu anda</h4>
              <div class="d-flex p-3">
                <!-- Instagram icon -->
                <i class="fab fa-instagram ms-5" style="font-size: 50px;"></i>
                <!-- TikTok icon -->
                <i class="fab fa-tiktok ms-5" style="font-size: 50px;"></i>
                <!-- YouTube icon -->
                <i class="fab fa-youtube ms-5" style="font-size: 50px;"></i>
              </div>
            </div>
          </div>
          <div class="col-7">
            <form>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label"
                  >Email address</label
                >
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                />
              </div>
              <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label"
                  >Example textarea</label
                >
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
              <button type="submit">Kirim Pesan</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
</script>
<style scoped>
h1,h2,h3,h4,h5,h6 {
  font-family: "Poppins", sans-serif;
}
p, a{
  font-family: "Quicksand";
}
.bg-custom {
  background: #bdfbbc;
}
.bg-custom2 {
  background: #2A9D3D;
}
</style>  