<template>
  <div class="container mt-5">
    <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded">
      <h1 style="text-align: start" class="mb-3">Semua Produk Konsultasi</h1>
      <button class="btn btn-primary mb-3" @click="showModal = true">
        Tambahkan Konsultasi
      </button>
      <div v-if="viewsConsultation.length > 0" class="shadow-lg p-3 mb-5 bg-body-tertiary rounded">
        <div class="row">
          <div class="container">
            <div style="overflow-x: auto">
              <table class="table table-striped" style="width: 250%">
                <thead>
                  <tr>
                    <th>ID Consultation</th>
                    <th>Header</th>
                    <th>Description</th>
                    <th>Tanggal Dibuat</th>
                    <th>Image</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="consultation in viewsConsultation" :key="consultation.id">
                    <td>
                      <span class="avatar">
                        <i class="fas fa-user"></i>
                        {{ consultation.id }}
                      </span>
                    </td>
                    <td>{{ consultation.header }}</td>
                    <td width="700">{{ consultation.description }}</td>
                    <td>{{ consultation.createdAt }}</td>
                    <td><img :src="consultation.imageUrl" alt="Image" width="100"></td>
                    <td>{{ consultation.answer[consultation.answer.length - 1] }}</td>
                    <td>
                      <button 
                      type="button" 
                      class="btn btn-primary btn-sm px-3"
                      @click="getDetailConsultation(consultation.id)"
                      >
                        Detail
                        <i class="fas fa-info-circle"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal -->
    <div v-if="showModal" class="modal" tabindex="-1" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambahkan Konsultasi Anda</h5>
            <button type="button" class="btn-close" @click="showModal = false"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="mb-3">
                <label for="header" class="form-label">Header untuk Consultasi:</label>
                <input type="text" class="form-control" v-model="consultation.header" required />
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">Deskripsi Konsultasi</label>
                <textarea class="form-control" rows="4" v-model="consultation.description" required></textarea>
              </div>
              <div class="mb-3">
                <label for="formFile" class="form-label">Upload Image</label>
                <input class="form-control" type="file" @change="handleFileUpload" required />
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../../../stores/auth";
import { ref, onMounted } from "vue";
import router from '@/router';

export default {
name: "AddConsultation",
setup() {
  const authStore = useAuthStore();
  const showModal = ref(false);
  const viewsConsultation = ref([]);
  const consultation = ref({
    header: "",
    description: "",
  });
  const file = ref(null);

  const handleFileUpload = (event) => {
    file.value = event.target.files[0];
  };

  const fetchgetViewsAllConsultationUser = async () => {
    try {
      const data = await authStore.viewsAllConsultationUser();
      viewsConsultation.value = data.consultations;
      console.log(data);
    } catch (error) {
      // console.error(error);
    }
  };

  const getDetailConsultation = (consultationId) => {
    router.push(`/consultation/${consultationId}`);
  };

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("header", consultation.value.header);
    formData.append("description", consultation.value.description);
    formData.append("image", file.value);

    try {
      await authStore.addConsultationUser(formData);
      showModal.value = false;
      alert("Consultation added successfully");
      location.reload();
    } catch (error) {
      console.error(error);
      alert("Failed to add product");
    }
  };

  onMounted(async () => {
     await fetchgetViewsAllConsultationUser ();
  });

  return {
      showModal,
      consultation,
      handleFileUpload,
      submitForm,
      viewsConsultation,
      getDetailConsultation
  };
},
};
</script>
<style scoped>
.modal {
background: rgba(0, 0, 0, 0.5);
}
</style>
