<template>
  <div class="about">
    <div class="content1">
      <div class="container">
        <div
          class="row align-items-center justify-content-center"
          id="headline"
        >
          <div class="col-6">
            <div id="headline-text">
              <h4 style="font-weight: 700">Tentang Kami</h4>
              <p style="font-size: 30px">
                ICH Institute merupakan lembaga yayasan yang bertujuan untuk
                menyiapkan generasi era digital yang berakhlak mulia mulai dari
                usia dini
              </p>
            </div>
          </div>
          <div class="col-6">
            <div class="img" id="headline-img">
              <img
                class="img-fluid"
                src="../../assets/img/bg-about2.png"
                alt="Foto Headline"
                width="400px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content2 bg-custom">
      <div class="container">
        <div class="row mt-5" id="headline">
          <div class="trusted d-flex mt-3">
            <i class="fa-solid fa-hand mt-1" style="font-size: 22px"></i>
            <p class="ms-2" style="font-size: 22px; font-weight: bold">
              Kenal lebih jauh
            </p>
          </div>
          <div class="row col-6 align-items-center justify-content-center">
            <div id="headline-text">
              <h2 style="text-align: start">
                Kami menyediakan kursus online dan layanan konsultasi
              </h2>
            </div>
          </div>
          <div class="col-6 p-5">
            <div class="shadow p-3 mb-5 bg-body-tertiary rounded">
              <div id="headline-text">
                <h6 style="font-weight: 600">Kursus Online</h6>
                <p>
                  Dengan learning management system memudahkan kamu dalam
                  menimba ilmu
                </p>
                <h6 style="font-weight: 600">Layanan Konsultasi</h6>
                <p>
                  Mitra terpercaya bagi bisnis kamu dalam memanfaatkan kekuatan
                  teknologi untuk mengubah bisnis anda lebih efektif dan efisien
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content3 mt-5 mb-5">
      <div class="container">
        <div
          class="row align-items-center justify-content-center"
          id="headline"
        >
          <div class="col-5">
            <div class="img" id="headline-img">
              <img
                class="img-fluid"
                src="../../assets/img/bg-about.png"
                alt="Foto Headline"
                width="620px"
              />
            </div>
          </div>
          <div class="col-7">
            <div id="headline-text">
              <div class="trusted d-flex">
                <i
                  class="fa-solid fa-handshake mt-1"
                  style="font-size: 22px"
                ></i>
                <p class="ms-2" style="font-size: 22px; font-weight: bold">
                  Komitmen
                </p>
              </div>
              <h1 style="font-weight: 700" class="mt-2">
                Belajar di ICH Institute
              </h1>
              <p>
                Tempat belajar yang paling asyik sehingga bikin kamu auto betah
                atau nyaman dalam belajar setiap hari.
              </p>
            </div>
            <div class="card" style="width: 38rem">
              <div class="card-body">
                <p class="card-text">
                  Memberikan solusi digital  yang tepat dan efektif
                  bagi setiap klien untuk membatu permasalah perusahaan agar mereka bisnis tumbuh dan berkembang.
                </p>
                <p class="card-text">
                  Menyediakan layanan E-Course yang inovatif dan komprehensif untuk membantu para pelajar mengembangkan keterampilan mereka di berbagai bidang.                
                </p>
                <p class="card-text">
                  Memudahkan konsultasi sehingga hubungan jangka panjang dengan klien untuk memastikan
                  kebutuhan dan tujuan bisnis mereka tercapai.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
p,
a {
  font-family: "Quicksand";
}
.mulai {
  border-style: solid;
  border-color: #2a9d3d;
  border-radius: 50px 25px;
  color: white;
  width: 100px;
}
.materi {
  border-style: solid;
  border-color: #2a9d3d;
  border-radius: 25px 50px;
  color: white;
  width: 100px;
}

.btn-selengkapnya {
  border-radius: 20px;
  width: 200px;
}

.card2 {
  margin-top: 80px;
}
.bg-custom {
  background: #bdfbbc;
}
.bg-custom2 {
  background: #2a9d3d;
}
hr {
  color: red;
}
span {
  text-align: center;
}
#headline2 {
  height: 500px;
}
.content2 {
  margin-top: -48px;
}
.fa-handshake {
  display: inline-block;
  width: 30px; /* Sesuaikan dengan ukuran ikon Anda */
  height: 30px; /* Sesuaikan dengan ukuran ikon Anda */
  border-radius: 50%; /* Membuat sudut menjadi lingkaran */
  background-color: darkorange; /* Warna latar belakang */
  text-align: center; /* Menengahkan ikon */
  line-height: 30px; /* Sesuaikan dengan ukuran ikon Anda */
  font-size: 22px; /* Sesuaikan dengan ukuran ikon Anda */
  color: white; /* Warna ikon */
}
.fa-hand {
  display: inline-block;
  width: 30px; /* Sesuaikan dengan ukuran ikon Anda */
  height: 30px; /* Sesuaikan dengan ukuran ikon Anda */
  border-radius: 50%; /* Membuat sudut menjadi lingkaran */
  background-color: darkorange; /* Warna latar belakang */
  text-align: center; /* Menengahkan ikon */
  line-height: 30px; /* Sesuaikan dengan ukuran ikon Anda */
  font-size: 22px; /* Sesuaikan dengan ukuran ikon Anda */
  color: white; /* Warna ikon */
}
</style>  
