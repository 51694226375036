<template>
  <div class="post-module">
    <h1>Edit Modul Baru</h1>
    <form @submit.prevent="submitForm" enctype="multipart/form-data" method="post">
      <div>
        <label for="judul">Judul Modul</label>
        <input type="text" v-model="module.judul" required />
      </div>
      <div>
        <label for="deskripsi">Deskripsi Modul</label>
        <textarea v-model="module.deskripsi" required></textarea>
      </div>
      <div>
        <label for="penulis">Penulis</label>
        <input type="text" v-model="module.penulis" required />
      </div>
      <div>
        <label for="tanggal_dibuat">Tanggal Dibuat</label>
        <input type="date" v-model="module.tanggal_dibuat" required />
      </div>
      <div class="mb-3">
        <label for="formFile" class="form-label"
          >Upload Modul PDF</label
        >
        <input class="form-control" type="file" id="formFile" @change="handleFileUpload" required />
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>
  
  <script>
import { useAuthStore } from "../../stores/auth";

export default {
  data() {
    return {
      module: {
        judul: "",
        deskripsi: "",
        penulis: "",
        tanggal_dibuat: "",
      },
      file: null,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async submitForm() {
      try {
        const authStore = useAuthStore();
        // console.log('Submitting module:', this.module);
        // const response = await authStore.addModule(this.module);
        const formData = new FormData();
        formData.append('judul', this.module.judul);
        formData.append('deskripsi', this.module.deskripsi);
        formData.append('penulis', this.module.penulis);
        formData.append('tanggal_dibuat', this.module.tanggal_dibuat);
        formData.append('pdf_url', this.file);  
        await authStore.updateModule(this.$route.params.id,formData);
        // console.log('Module added successfully:', response);
        alert("Modul berhasil di Update");
        this.$router.replace({ name: "AllModulProduct" });
      } catch (error) {
        alert("Terjadi kesalahan saat mengupdate modul: " + error);
      }
    },
  },
};
</script>
  
  <style scoped>
.post-module {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}
form div {
  margin-bottom: 10px;
}
label {
  display: block;
  margin-bottom: 5px;
}
input[type="text"],
input[type="url"],
input[type="date"],
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>
  