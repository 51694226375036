<template>
    <div>
      <h1>404: Not Found</h1>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  h1{
      text-align: center;
  }
  </style>