<template>
  <div>
    <div class="user" v-if="userAuthenticated">
      <div class="container mt-5" v-if="consultation">
        <div class="d-flex align-items-center mb-3">
          <div>
            <h4>{{ consultation.header }}</h4>
            <small class="text-muted">{{ consultation.createdAt }}</small>
          </div>
        </div>
        <div>
          <img
            :src="consultation.imageUrl"
            alt="Consultation Image"
            class="consultation-image"
          />
          <div class="d-flex align-items-center mb-3"></div>
          <p>{{ consultation.description }}</p>
        </div>

        <div class="mt-5">
          <div class="d-flex align-items-center mb-3">
            <img
              src="../../../assets/img/Bg-home2.png"
              alt="User Avatar"
              class="rounded-circle me-3"
              style="width: 50px; height: 50px"
            />
            <h6 class="mb-0">{{ consultation.userName }}</h6>
          </div>
          <div class="mb-3"></div>

          <form @submit.prevent="submitComment">
            <textarea
              v-model="newComment.answer"
              class="form-control"
              rows="3"
              placeholder="Tuliskan komentar Anda..."
            ></textarea>
            <button type="submit" class="btn btn-primary mt-2">Balas</button>
          </form>

          <div class="mt-4">
            <h4>{{ consultation.answer.length }} Jawaban</h4>
          </div>
          <div
            class="card mt-4"
            v-for="(comment, index) in consultation.answer"
            :key="index"
          >
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <img
                  src="../../../assets/img/Bg-home2.png"
                  alt="User Placeholder"
                  class="rounded-circle me-3"
                  style="width: 50px; height: 50px"
                />
                <div>
                  <h6 class="mb-0">{{ consultation.userName }}</h6>
                  <small class="text-muted">1 hari yang lalu</small>
                </div>
              </div>
              <p>{{ comment }}</p>
              <div class="d-flex justify-content-between">
                <div>
                  <button class="btn btn-link">Balas</button>
                  <button class="btn btn-link">Bagikan</button>
                </div>
                <div class="d-flex align-items-center">
                  <button class="btn btn-link">👍 0</button>
                  <button class="btn btn-link">👎 0</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="admin" v-if="adminAuthenticated">
      <!-- Admin-specific content  -->
      <div class="container mt-5" v-if="consultationAdmin">
        <div class="d-flex align-items-center mb-3">
          <div>
            <h4>{{ consultationAdmin.header }}</h4>
            <small class="text-muted">{{ consultationAdmin.createdAt }}</small>
          </div>
        </div>
        <div>
          <img
            :src="consultationAdmin.imageUrl"
            alt="Consultation Image"
            class="consultation-image"
          />
          <div class="card align-items-left mb-3 p-2 mt-2">
            <p>{{ consultationAdmin.description }}</p>
          </div>
        </div>

        <div class="mt-5">
          <div>
            <h4>{{ consultationAdmin.answer.length }} Jawaban</h4>
          </div>
          <div
            class="card mt-4"
            v-for="(comment, index) in consultationAdmin.answer"
            :key="index"
          >
            <div class="card-body">
              <p>{{ comment }}</p>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <form @submit.prevent="submitCommentAdmin">
            <textarea
              v-model="newComment.answer"
              class="form-control"
              rows="3"
              placeholder="Tuliskan komentar Anda..."
            ></textarea>
            <button type="submit" class="btn btn-primary mt-2">Balas</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "../../../stores/auth";

export default {
  setup() {
    const route = useRoute();
    const consultation = ref(null);
    const consultationAdmin = ref(null);
    const authStore = useAuthStore();
    const consultationId = route.params.id;
    const newComment = ref({
      answer: "",
    });
    const adminAuthenticated = () => {
      return authStore.AdminAuthenticated;
    };

    const userAuthenticated = () => {
      return authStore.UserAuthenticated;
    };

    const submitComment = async () => {
      const formData = new FormData();
      formData.append("answer", newComment.value.answer);
      try {
        await authStore.addAnswerConsultationUser(consultationId, formData);
        alert("Answer added successfully");
        location.reload();
      } catch (error) {
        console.error(error);
        alert("Failed to add product");
      }
    };
    const submitCommentAdmin = async () => {
      const formData = new FormData();
      formData.append("answer", newComment.value.answer);
      try {
        await authStore.addAnswerConsultationAdmin(consultationId, formData);
        alert("Answer added successfully");
        location.reload();
      } catch (error) {
        console.error(error);
        alert("Failed to add product");
      }
    };

    const fetchgetViewsDetailConsultation = async () => {
      try {
        const data = await authStore.getDetailConsultationUser(consultationId);
        consultation.value = data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchgetViewsDetailConsultationAdmin = async () => {
      try {
        const data = await authStore.getDetailConsultationAdmin(consultationId);
        consultationAdmin.value = data.detailConsultation;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(async () => {
      if (!authStore.AdminAuthenticated) {
        await fetchgetViewsDetailConsultation();
      }
      if (!authStore.UserAuthenticated) {
        await fetchgetViewsDetailConsultationAdmin();
      }
    });

    return {
      consultation,
      consultationAdmin,
      newComment,
      submitComment,
      submitCommentAdmin,
      fetchgetViewsDetailConsultation,
      adminAuthenticated,
      userAuthenticated,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
}

.consultation-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  max-height: 500px; /* Optional: Set a max height to ensure the image isn't too tall */
}

img {
  max-width: 100%;
  height: auto;
}

.rounded-circle {
  width: 50px;
  height: 50px;
}
</style>
